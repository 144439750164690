/* eslint-disable no-undef */
import React, { useState, useEffect } from 'react';
import '../../common/fontsize.css';
import '../../index.css';
import {
  Container,
  Grid,
  Typography,
  Button,
  Divider,
  CardMedia,
  Box,
  Tooltip,
  // List,
  // ListItem,
  FormControl,
  OutlinedInput,
  Link,
  ListItem,
  List,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
} from '@mui/material';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { ReactComponent as ElynkerLogo } from '../../assets/elynker-white-logo.svg';
import elynker_default_image from '../../assets/elynker_default_image.jpg';

import { ReactComponent as SearchIcon } from '../../assets/search-icon.svg';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
// import { ReactComponent as MenuIcon } from '../../assets/menu.svg';
import { ReactComponent as UserIcon } from '../../assets/user-white-icon.svg';
import { ReactComponent as ShopIcon } from '../../assets/shop-icon.svg';
import { styled } from '@mui/material/styles';
import Paper from '@mui/material/Paper';
import Card from '@mui/material/Card';
import e_business_bg from '../../assets/elynker_app_icon.svg';

import CardContent from '@mui/material/CardContent';
import Accordion from '@mui/material/Accordion';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import CircleIcon from '@mui/icons-material/Circle';
import { Carousel } from 'react-responsive-carousel';
import 'react-responsive-carousel/lib/styles/carousel.min.css';
import { getAllProducts } from '../OfferedServices/redux/actions/offered-services-form-action';
import { ReactComponent as QuotesIcon } from '../../assets/quotes-icon.svg';
import GradientCircularProgress from '../../components/ProgressCircle/ProgressCircle';
// import { ReactComponent as InsightsIcon } from '../../assets/insights-icon.svg';
// import { ReactComponent as InsightsGainArrowIcon } from '../../assets/insight-gain-arrow.svg';
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min';
import { ReactComponent as ViewMoreIcon } from '../../assets/double-right-arrow-icon.svg';
import { ReactComponent as RightArrowIcon } from '../../assets/right-black-arrow-icon.svg';

import {
  getCurrentProfileProgress,
  getFLCurrentProfileProgress,
} from '../../utils/percent';
import { useDispatch, useSelector } from 'react-redux';
const StyledImageContainer = styled('div')({
  border: '1px solid #E3E3E3',
  padding: '10px 15px',
  textAlign: 'left',
  borderRadius: '8px',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between',
});
import { getCertificateData } from '../FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { getBannerData } from '../FreeLancer/FL-AddBio/redux/actions/fl-add-bio-action';
import { getAllService } from '../FreeLancer/FL-UploadServiceCatalog/redux/actions/fl-upload-service-action';
import { getResumeData } from '../FreeLancer/FL-UploadResume/redux/actions/fl-upload-resume-action';
// import { ReactComponent as BusinessServiceImg } from '../../assets/business-service-web-img.svg';
// import { ReactComponent as ElynkerForImg } from '../../assets/elynker-for-image.svg';
import ElynkerLogoComponent from '../../components/ElynkerLogo/ElynkerLogo';
import { any, array, func, number, object, bool, string } from 'prop-types';
import {
  ELYNKER_COMPLETION,
  FREELANCER_CATEGORY,
  BUSINESS_CATEGORY,
  ELYNKER_TOOLS,
  // CREDIT_SERVICES,
  HOME_PAGE_FL_COUNT,
  JOBLIST,
  POSTJOB,
  POSTJOB_TITLE,
  POSTJOB_SUBTITLE,
  POSTJOB_REDIRECT,
  POSTJOB_BUTTON,
  DIALOG_NOT_LOGIN_MSG,
  DIALOG_TITLE,
  FREELANCER_ID,
  BUSINESS_ID,
  B2C_ID,
  CLICKED,
  HOMEPAGE,
  SEO_HOMEPAGE_TITLE,
  SEO_HOMEPAGE_URL,
  SEO_HOMEPAGE_DESC,
  SEO_FAV_ICON_URL,
  SEO_HOMEPAGE_KEYWORDS,
} from '../../utils/constants';
import {
  setSelectedVCategory,
  setSelectedVCategoryUUID,
} from '../FLFreelance/redux/actions/fl-business-action';
import ImageProfile from '../../components/CustomImageView/ImgProfile';
import { CATEGORY_LOGO_BASE_URL } from '../../utils/APIs/api';
import { getAllJobs } from '../JobPosted/redux/actions/create-jobposted-action';
import { saveJobSearchText } from '../JobListing/redux/actions/job-listing-action';
import { saveJobData } from '../JobPostDetail/redux/actions/job-post-details-action';
import { setJobID } from '../JobPostApply/redux/actions/job-post-apply-action';
import { ConfirmationDialog } from '../../components/ActionPopup';
import ReactGA from 'react-ga4';
import { GAEvents } from '../../utils/helper-funtions';
import WebFooter from '../../components/Footer/WebFooter';
import PreRegisterModal from './PreRegisterModal';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { SET_ENQUIRY } from '../B2BCategory/redux/types';
import { Helmet } from 'react-helmet';
// import CheckCircleIcon from '@mui/icons-material/CheckCircle';

const theme = createTheme();
const categoryText =
  "Grow Your Business with Elynker – Simple, Fast, Profitable";

const WebHomepageForm = ({
  registrationData,
  flancerLvl1Categories,
  // CreditServices,
  userStatus,
  resetAllVendorData,
  widgets,
  b2bLvl1Categories,
  tools,
  // handleClickOnDetail,
  // handleVendorSearchInput,
  handleViewTools,
  vendorSearchVal,
  // open,
  handleClick,
  searchOptions,
  // setSearchOptions,
  // resetSearchVendors,
  carouselItems,
  handleTerms,
  handlePrivacy,
  openInNewTab,
  homepageJobsActive,
  // handleShippingDelivery,
  // handleCancellationRefund,
  handleContactUs,
  CreditServicesTemp,
  openModal,
  handleClose,
  // handleOpen,
  handleConfirm,
  userType,
  enquiry,
  waEnquiry,
  openPreRegisterModal,
  setOpenPreRegisterModal,
  startOnboarding,
  startSubscription,
  openSuccCallModal,
  handleCloseSuccCallModal,
  errorMsg
}) => {
  useEffect(() => {
    ReactGA.send({
      hitType: 'pageview',
      page: '/',
      category: 'page_home',
      title: 'Web Home Page Open',
    });
    if (
      userData?.registration.registration_type == BUSINESS_ID &&
      enquiry &&
      enquiry?.isTrue
    ) {
      history.replace(enquiry?.redirect_to);
    }
    if (userData && waEnquiry && waEnquiry?.isTrue) {
      history.push(waEnquiry?.redirect_to + waEnquiry?.vendor_slug);
    }
  }, []);
  // Vendor SearchBox States
  const ElynkerCompletion = ({ itm, idx }) => {
    console.log(itm, idx);
    return (
      <>
        {userData != null && registration_type !== B2C_ID && (
          <Card
            className="complete-profile-web-card"
            sx={{ marginBottom: '45px' }}
          >
            <CardContent className="complete-profile-web-content">
              {profileProgress !== 100 && (
                <div style={{ position: 'relative', width: 90, height: 90 }}>
                  <GradientCircularProgress value={profileProgress} size={90} />
                  <Typography
                    variant="caption"
                    className="progress-percentage fs-16 text-darkgrey fw-bold font-skmodernist"
                  >
                    {profileProgress}%
                  </Typography>
                </div>
              )}
              <div className="align-items-center d-flex justify-content-between ms-4 w-100">
                <Typography
                  variant="body2"
                  className="fs-24 text-darkgrey fw-bold font-skmodernist"
                >
                  {profileProgress !== 100
                    ? userStatus
                      ? 'Profile ' + registrationData?.status
                      : 'Profile Completed'
                    : 'View profile details'}
                </Typography>
                <Button
                  variant="contained"
                  className="complete-profile-btn"
                  onClick={() => {
                    profileProgress !== 100
                      ? userData?.registration?.registration_type == BUSINESS_ID
                        ? history.push('/update-profile')
                        : userData?.registration?.registration_type ==
                          FREELANCER_ID
                          ? history.push('/fl-update-profile')
                          : null
                      : history.push('/profile');
                  }}
                >
                  {profileProgress !== 100
                    ? 'Complete Your Profile'
                    : 'Dashboard'}
                </Button>
              </div>
            </CardContent>
          </Card>
        )}
        {/* {userData !== null && registration_type !== 1 ? (
          profileProgress == 100 ? (
            <Card className="insights-card" key={`B${itm?.id}-${idx}`}>
              <CardContent className="insights-content">
                <div className="d-flex justify-content-between">
                  <div>
                    <Typography
                      variant="h6"
                      className=" fs-15 text-darkgrey fw-normal mb-2 font-skmodernist"
                    >
                      Your Insights
                    </Typography>
                    <Typography
                      variant="h4"
                      className=" fs-24 text-secondary fw-normal mb-2 font-skmodernist"
                    >
                      10 Connects
                    </Typography>
                  </div>
                  <div>
                    <InsightsIcon />
                  </div>
                </div>
                <div className="d-flex mb-3">
                  <div className="d-flex align-items-center">
                    <InsightsGainArrowIcon />
                    <Typography
                      variant="h4"
                      className="fs-14 text-gain ms-1 fw-normal font-gotham"
                    >
                      100%
                    </Typography>
                  </div>
                  <Typography
                    variant="h4"
                    className="fs-14 text-connects ms-4 fw-normal font-gotham"
                  >
                    Connect Made
                  </Typography>
                </div>

                <Button variant="contained" className="complete-profile-btn">
                  See your dashboard
                </Button>
              </CardContent>
            </Card>
          ) : (
            <></>
          )
        ) : (
          <></>
        )} */}
      </>
    );
  };
  const BusinessCategory = ({ itm, idx }) => {
    const sortedArray = b2bLvl1Categories?.sort((a, b) => {
      if (a.rank === 0) return 1;
      if (b.rank === 0) return -1;
      return a.rank - b.rank;
    });
    return (
      <>
        <Typography
          variant="h4"
          alignItems="center"
          className="fs-36 text-black fw-bold font-skmodernist text-center"
          sx={{ marginTop: '45px', marginBottom: '40px', lineHeight: '50px' }}
        >
          Discover Your Ideal Business Partner for Expansion
        </Typography>
        <Grid
          container
          spacing={1}
          key={`B${itm?.id}-${idx}`}
          sx={{ width: '80%', margin: '0 auto' }}
        >
          {sortedArray &&
            sortedArray?.slice(0, HOME_PAGE_FL_COUNT).map((bCategory, k) => (
              <Grid item md={3} key={k}>
                <Item sx={{ cursor: 'pointer' }}>
                  <StyledImageContainer
                    onClick={() => {
                      const gaName = bCategory.title;
                      const gaId = bCategory.id;
                      const gaPos = bCategory.rank;

                      GAEvents(HOMEPAGE, 'Business category', CLICKED, {
                        item_name: gaName,
                        item_id: gaId,
                        item_position: gaPos,
                        list_name: 'homepage_business_categories',
                      });
                      // ReactGA.event('business_categoty_item', {
                      //   category: 'business_categoty_item',
                      //   action: `Clicked on business categoty item on WebHomepage`,
                      //   ...{
                      //     userId: userData?.id || "",
                      //     title: bCategory?.title || ""
                      //   },
                      // });
                      resetAllVendorData();
                      dispatch(setSelectedVCategory(bCategory));
                      dispatch(setSelectedVCategoryUUID(bCategory?.uuid));
                      history.push('/b2b-category' + `/${bCategory?.uuid}`);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <ImageProfile
                        path={CATEGORY_LOGO_BASE_URL + bCategory.image_path}
                        placeholder={e_business_bg}
                        loadError={elynker_default_image}
                        style={{
                          // marginBottom: '8px',
                          width: '60px',
                          borderRadius: '8px',
                          height: '60px',
                        }}
                        loading="lazy"
                      />
                      <Typography className="fs-13 text-darkgrey font-gotham fw-normal ms-2">
                        {bCategory.title}
                      </Typography>
                    </div>
                    <RightArrowIcon />
                  </StyledImageContainer>
                </Item>
              </Grid>
            ))}
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ marginTop: '45px', marginBottom: '25px' }}
        >
          <Button
            className="text-secondary font-skmodernist fw-bold fs-16 text-capitalize align-items-center"
            onClick={() => {
              GAEvents(HOMEPAGE, 'Business category', CLICKED, {
                item_name: 'More', // e.g., 'Agriculture & Farming'
                item_id: 0,
                item_position: -1,
                list_name: 'homepage_business_categories',
              });
              // ReactGA.event('business_categoty_more_item', {
              //   category: 'business_categoty_more_item',
              //   action: `Clicked on business categoty more item on WebHomepage`,
              //   ...{
              //     userId: userData?.id || "",
              //     title: "More"
              //   },
              // });
              history.push('/b2b-business');
            }}
          >
            View More{' '}
            <ViewMoreIcon style={{ marginTop: '3px', marginLeft: '6px' }} />
          </Button>
        </Box>
        <Divider
          component="div"
          className="main-divider my-3"
          role="presentation"
          key={itm + idx}
        />
      </>
    );
  };
  const FreelancerCategory = ({ itm, idx }) => {
    return (
      <>
        <Typography
          variant="h4"
          alignItems="center"
          className="fs-36 text-black fw-bold font-skmodernist text-center"
          sx={{ marginBottom: '40px', lineHeight: '50px' }}
        >
          Connect with Reliable Experts for Your Projects
        </Typography>
        <Grid
          container
          spacing={1}
          key={`FL${itm?.id}-${idx}`}
          sx={{ width: '80%', margin: '0 auto' }}
        >
          {flancerLvl1Categories &&
            flancerLvl1Categories
              ?.slice(0, HOME_PAGE_FL_COUNT)
              .map((fCategory, k) => (
                <Grid item md={3} key={k}>
                  <Item sx={{ cursor: 'pointer' }}>
                    <StyledImageContainer
                      onClick={() => {
                        resetAllVendorData();
                        dispatch(setSelectedVCategory(fCategory));
                        dispatch(setSelectedVCategoryUUID(fCategory?.uuid));
                        history.push('/fl-freelance-category');
                      }}
                    >
                      <div className="d-flex align-items-center">
                        <ImageProfile
                          path={CATEGORY_LOGO_BASE_URL + fCategory.image_path}
                          placeholder={e_business_bg}
                          loadError={elynker_default_image}
                          style={{
                            // marginBottom: '8px',
                            width: '60px',
                            borderRadius: '8px',
                            height: '60px',
                          }}
                          loading="lazy"
                        />
                        <Typography className="fs-13 text-darkgrey font-gotham fw-normal ms-2">
                          {fCategory.title}
                        </Typography>
                      </div>
                      <RightArrowIcon />
                    </StyledImageContainer>
                  </Item>
                </Grid>
              ))}
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ marginTop: '45px', marginBottom: '25px' }}
        >
          <Button
            className="text-secondary font-skmodernist fw-bold fs-16 text-capitalize align-items-center"
            onClick={() => {
              history.push('/fl-freelance');
            }}
          >
            View More{' '}
            <ViewMoreIcon style={{ marginTop: '3px', marginLeft: '6px' }} />
          </Button>
        </Box>
        <Divider
          component="div"
          className="main-divider mb-3"
          role="presentation"
          key={itm + idx}
        />
      </>
    );
  };
  const ElynkerTools = () => {
    return (
      <div>
        <Typography
          variant="h4"
          align="left"
          className="fs-36 text-black fw-bold font-skmodernist text-center"
          sx={{ marginTop: '45px', marginBottom: '40px', lineHeight: '50px' }}
        >
          Get the Support You Need to Grow Your Business
        </Typography>
        <Grid
          container
          spacing={1}
          sx={{ width: '80%', margin: '0 auto', justifyContent: 'center' }}
        >
          {tools.slice(0, 6).map((tool, index) => (
            <>
              {tool?.service_type === 'Tools' && (
                <Grid
                  xs={4}
                  sm={4}
                  md={2}
                  key={index}
                  sx={{
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'center',
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: '110px',
                      height: '110px',
                      borderRadius: '8px',
                      cursor: 'pointer',
                    }}
                    image={tool?.cover_image}
                    alt={tool?.title}
                  // onClick={() => {
                  //   handleClickOnDetail(tool);
                  // }}
                  />
                  <Typography
                    className="fs-13 text-darkgrey font-gotham mt-2"
                    align="center"
                  >
                    {tool.title}
                  </Typography>
                </Grid>
              )}
            </>
          ))}
        </Grid>
        <Box
          display="flex"
          justifyContent="center"
          sx={{ marginTop: '45px', marginBottom: '25px' }}
        >
          <Button
            className="text-secondary font-skmodernist fw-bold fs-16 text-capitalize align-items-center"
            onClick={() => {
              GAEvents(HOMEPAGE, 'select_business_service', {
                item_name: 'More',
                item_id: 0,
                item_position: -1,
                list_name: 'homepage_services',
              });
              handleViewTools();
            }}
          >
            View More{' '}
            <ViewMoreIcon style={{ marginTop: '3px', marginLeft: '6px' }} />
          </Button>
        </Box>
        <Divider
          component="div"
          className="main-divider mb-3"
          role="presentation"
        />
      </div>
    );
  };

  const JobsList = ({ itm, idx }) => {
    console.log(itm, idx);
    return (
      <>
        <Typography
          variant="h4"
          align="left"
          alignItems="center"
          className="fs-36 text-black fw-bold font-skmodernist text-center"
          sx={{ marginTop: '45px', marginBottom: '40px', lineHeight: '50px' }}
        >
          Jobs Near You
        </Typography>

        <Grid container columnSpacing={3} justifyContent={'center'}>
          {homepageJobsActive.slice(0, 2).map((item) => (
            <Grid item xs={12} md={5} key={item.id}>
              <ListItem className="list-item">
                <Card
                  className="card d-flex"
                  onClick={() => {
                    dispatch(saveJobData(item));
                    dispatch(setJobID(item?.id));
                    history.push('/job-detail', {
                      from: '/homepage',
                      to: '/job-detail',
                    });
                  }}
                >
                  <CardMedia
                    component="img"
                    sx={{
                      width: '130px',
                      height: '120px',
                      borderRadius: '8px',
                    }}
                    image={
                      item?.categories[0]?.image_path
                        ? CATEGORY_LOGO_BASE_URL +
                        item?.categories[0]?.image_path
                        : require('../../assets/elynker_default_image.jpg')
                    }
                    alt={item?.title}
                  // onClick={() => { handleClickOnDetail(tool) }}
                  />
                  <CardContent className="content">
                    <div className="d-flex justify-content-between align-items-center">
                      <Typography
                        variant="h5"
                        className="fs-16 text-black mb-2 fw-bold font-skmodernist"
                        component="div"
                      >
                        {item.title}
                      </Typography>
                      <div className="actions mt-0">
                        <IconButton className="cat-edit-icon p-0">
                          <RightArrowIcon />
                        </IconButton>
                      </div>
                    </div>

                    <Typography
                      variant="body2"
                      className="fs-13 text-grey mb-2 font-gotham"
                    >
                      {item.description}
                    </Typography>

                    <Typography
                      variant="h5"
                      className="fs-13 text-darkgrey mb-0 font-skmodernist"
                    >
                      Upto
                    </Typography>

                    <Typography
                      variant="h5"
                      className="fs-15 text-secondary fw-bold mb-1 font-skmodernist"
                    >
                      ₹ {item.salary_offered}/{' '}
                      <span className="fs-12 text-grey fw-normal">Month</span>
                    </Typography>
                  </CardContent>
                </Card>
              </ListItem>
            </Grid>
          ))}
        </Grid>

        <Box
          display="flex"
          justifyContent="center"
          sx={{ marginTop: '45px', marginBottom: '25px' }}
        >
          <Button
            type="submit"
            className="text-secondary font-skmodernist fw-bold fs-16 text-capitalize align-items-center"
            onClick={() => {
              dispatch(saveJobSearchText('%20'));
              history.push('/job-listing', {
                from: '/homepage',
                to: '/job-listing',
              });
            }}
          >
            View More{' '}
            <ViewMoreIcon style={{ marginTop: '2px', marginLeft: '6px' }} />
          </Button>
        </Box>
        <Divider
          component="div"
          className="main-divider mb-3"
          role="presentation"
        />
      </>
    );
  };

  const PostJob = ({ itm, idx }) => {
    console.log(itm, idx);
    return (
      <>
        <Grid
          container
          columnSpacing={3}
          justifyContent={'center'}
          className="mt-4"
        >
          <Grid item md={6} lg={6} xs={12} key={1}>
            <Card className="credit-services-card test-card mb-3">
              <CardContent className="post-job-content">
                <div className="post-job-content-tint" />
                <div style={{ zIndex: 2, position: 'relative' }}>
                  <Typography
                    variant="h5"
                    className="fs-16 text-white mb-2 fw-bold font-skmodernist"
                    component="div"
                  >
                    {POSTJOB_TITLE}
                    {/* Credit Services */}
                  </Typography>
                  <Typography
                    variant="body2"
                    className="fs-11 text-white mb-3 font-gotham"
                  // component="div"
                  >
                    {POSTJOB_SUBTITLE}
                    {/* Credit Services */}
                  </Typography>
                  <Button
                    variant="contained"
                    className="learn-more-btn"
                    onClick={() => {
                      history.push(POSTJOB_REDIRECT);
                    }}
                  >
                    {POSTJOB_BUTTON}
                  </Button>
                </div>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </>
    );
  };

  const returnRenderComponent = (widget) => {
    const widgetMapping = {
      [ELYNKER_COMPLETION]: <ElynkerCompletion />,
      [FREELANCER_CATEGORY]: <FreelancerCategory />,
      [BUSINESS_CATEGORY]: <BusinessCategory />,
      // [CREDIT_SERVICES]:
      //   userData?.registration?.registration_type !== 3 ? (
      //     <CreditServices />
      //   ) : (
      //     <></>
      //   ),
      [ELYNKER_TOOLS]:
        userData?.registration?.registration_type !== 3 ? (
          <ElynkerTools />
        ) : (
          <></>
        ),
      [JOBLIST]: <JobsList />,
      [POSTJOB]:
        userData?.registration?.registration_type !== 3 ? <PostJob /> : <></>,
    };

    if (widgetMapping[widget.widget_name]) {
      return widgetMapping[widget.widget_name];
    }

    return null;
  };
  const renderHomePageWidgets = () => {
    return [...widgets, { widget_name: 'credit services', rank: '999' }]
      ?.filter((wg) => wg?.rank)
      ?.sort((a, b) => Number(a?.rank) - Number(b?.rank))
      .map((widget) => returnRenderComponent(widget));
  };

  const history = useHistory();
  // const location = useLocation()
  const [profileProgress, setProfileProgress] = useState(0);
  const dispatch = useDispatch();
  const { userData } = useSelector((state) => state.signin);
  const { businessDocuments } = useSelector((state) => state.b2bOnboardStep);
  // const { topLevelAddedCategories } = useSelector(state => state.userProfile)
  const { socialLinks } = useSelector((state) => state.profileDetails);
  const { allProducts } = useSelector((state) => state.offeredServices);
  const registration_type = useSelector(
    (state) => state.signin.userData?.registration?.registration_type
  );

  const { resumeData } = useSelector((state) => state.flUploadResume);
  const { projects } = useSelector((state) => state.flAddBio);
  const { allServices } = useSelector((state) => state.flUploadService);
  const { certificates } = useSelector((state) => state.flProfileDetails);

  useEffect(() => {
    dispatch(getResumeData(userData?.registration?.id));
    dispatch(getBannerData(userData?.registration?.id));
    dispatch(getAllService(userData?.registration?.id));
    dispatch(getAllProducts(userData?.registration?.id));
    dispatch(getCertificateData(userData?.registration?.id));
    dispatch(getAllJobs());
  }, []);
  useEffect(() => {
    if (userData?.registration?.registration_type == BUSINESS_ID) {
      let progress = getCurrentProfileProgress(
        userData?.registration,
        allProducts,
        businessDocuments,
        socialLinks
      );
      setProfileProgress(Math.round(progress));
    }

    if (userData?.registration?.registration_type == FREELANCER_ID) {
      let flProgress = getFLCurrentProfileProgress(
        userData?.registration,
        resumeData,
        projects,
        allServices,
        certificates
      );
      setProfileProgress(Math.round(flProgress));
    }
  }, [resumeData, projects, allServices, certificates]);

  const Item = styled(Paper)(({ theme }) => ({
    padding: theme.spacing(1),
    textAlign: 'center',
    color: theme.palette.text.secondary,
    margin: 0,
    boxShadow: 'none',
  }));

  // Carousel
  ElynkerCompletion.propTypes = {
    itm: object,
    idx: number,
  };

  FreelancerCategory.propTypes = {
    itm: object,
    idx: number,
  };

  BusinessCategory.propTypes = {
    itm: object,
    idx: number,
  };

  Card.propTypes = {
    itm: object,
    idx: number,
  };

  ElynkerTools.propTypes = {
    itm: array,
    idx: number,
  };

  JobsList.propTypes = {
    itm: object,
    idx: number,
  };

  PostJob.propTypes = {
    itm: object,
    idx: number,
  };



  // const handleClosePreRegisterModal = (e) => {
  //   e.preventDefault();
  //   setOpenPreRegisterModal(false);
  // };

  return (
    <ThemeProvider theme={theme}>
      <Helmet>
        <meta charSet="utf-8" />
        <title>{SEO_HOMEPAGE_TITLE}</title>
        <link rel="canonical" href={SEO_HOMEPAGE_URL} />
        <meta name="description" content={SEO_HOMEPAGE_DESC} />
        <meta property="og:title" content={SEO_HOMEPAGE_URL} />
        <meta property="og:description" content={SEO_HOMEPAGE_DESC} />
        <meta property="og:url" content={SEO_HOMEPAGE_URL} />
        <meta property="og:image" content={SEO_FAV_ICON_URL} />
        <meta property="og:type" content="website" />
        <meta
          name="keywords"
          content={SEO_HOMEPAGE_KEYWORDS}
        />
      </Helmet>
      <Container
        component="main"
        maxWidth="xl"
        sx={{ background: '#FFFFFF', padding: '0px !important' }}
      >
        <Dialog
          open={openSuccCallModal}
          onClose={handleCloseSuccCallModal}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
          maxWidth="sm"
          fullWidth
        >
          <DialogTitle className="custom-border-header pb-0">
            <Box className="position-relative d-flex justify-content-between">
              <Typography
                className="fs-22 fw-bold font-skmodernist"
                id="alert-dialog-title"
              >
                {DIALOG_TITLE}
              </Typography>
              <Box>
                <CloseIcon
                  onClick={() => {
                    dispatch({
                      type: SET_ENQUIRY,
                      payload: {},
                    });
                    handleCloseSuccCallModal();
                    // history.replace('/login');
                  }}
                  style={{ cursor: 'pointer' }}
                />
              </Box>
            </Box>
          </DialogTitle>
          <DialogContent>
            <DialogContentText id="alert-dialog-description">
              {errorMsg}
            </DialogContentText>
          </DialogContent>

          <DialogActions className="modal-footer">
            <Button
              onClick={() => {
                handleCloseSuccCallModal();
                history.replace('/login');
              }}
              className="btn-save"
            >
              Ok
            </Button>
          </DialogActions>
        </Dialog>
        <PreRegisterModal
          open={openPreRegisterModal}
          onClose={() => setOpenPreRegisterModal(false)}
          startOnboarding={startOnboarding}
          startSubscription={startSubscription}
        />
        <ConfirmationDialog
          open={openModal}
          handleClose={handleClose}
          title={DIALOG_TITLE}
          content={
            userType !== B2C_ID
              ? DIALOG_NOT_LOGIN_MSG
              : 'Please Login as Seller to use this feature'
          }
          onConfirm={handleConfirm}
          confirmText="Yes"
          cancelText="No"
        />
        <Grid
          container
          justifyContent="center"
          alignItems="center"
          style={{ height: '67vh' }}
        >
          <Grid item xs={12}>
            <div className="homepage-web-background">
              <div className="homepage-web-background-tint" />
              <div style={{ zIndex: 2, position: 'relative' }}>
                <AppBar
                  position="static"
                  sx={{
                    marginBottom: '80px',
                    width: '80%',
                    marginLeft: 'auto',
                    marginRight: 'auto',
                    zIndex: 2,
                  }}
                >
                  <Toolbar>
                    {/* <IconButton
                    size="large"
                    edge="start"
                    color="inherit"
                    aria-label="menu"
                    sx={{ mr: 2 }}
                  >
                    <MenuIcon />
                  </IconButton> */}
                    <div style={{ flexGrow: 1 }}>
                      <ElynkerLogoComponent>
                        <ElynkerLogo width={'100px'} height={'30px'} />
                      </ElynkerLogoComponent>


                    </div>
                    {
                      <div>
                        {!registration_type && (
                          <Button
                            onClick={() => {
                              GAEvents(
                                HOMEPAGE,
                                'Home page login button',
                                CLICKED,
                                {
                                  user_id:
                                    userData?.id ||
                                    sessionStorage.getItem('random_id'),
                                  reg_uuid:
                                    userData?.registration?.uuid ||
                                    sessionStorage.getItem('random_id'),
                                }
                              );
                              // ReactGA.event('btn_login', {
                              //   category: 'btn_login',
                              //   action:
                              //     'Clicked on login button on WebHomepage',
                              //   ...{
                              //     userId: userData?.id || '',
                              //   },
                              // });
                              history.push('/login');
                            }}
                            variant="contained"
                            className="login-btn"
                          >
                            Login
                          </Button>
                        )}
                        {registration_type && (
                          <Tooltip
                            title="Profile"
                            style={{ cursor: 'pointer' }}
                          >
                            <IconButton
                              size="medium"
                              aria-label="user icon"
                              aria-controls="menu-appbar"
                              aria-haspopup="true"
                              color="inherit"
                              onClick={() => {
                                registration_type !== 1
                                  ? history.push('/profile')
                                  : history.push('/b2c-profile');
                              }}
                            >
                              <UserIcon />
                            </IconButton>
                          </Tooltip>
                        )}

                        {registration_type === 1 && (
                          <IconButton
                            size="large"
                            aria-label="user icon"
                            aria-controls="menu-appbar"
                            onClick={() =>
                              history.push('/complete-profile', {
                                fromHomePage: true,
                              })
                            }
                            color="inherit"
                            className="pe-0"
                          >
                            <ShopIcon />
                          </IconButton>
                        )}
                      </div>
                    }
                  </Toolbar>
                </AppBar>
                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-40 text-white fw-bold font-skmodernist text-center"
                  gutterBottom
                  style={{
                    whiteSpace: 'pre-line',
                    lineHeight: '60px',
                    width: '80%',
                    margin: '0 auto 0px auto',
                  }}
                >
                  {categoryText}
                </Typography>
                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-30 text-white fw-bold font-skmodernist text-center"
                  gutterBottom
                  style={{
                    whiteSpace: 'pre-line',
                    lineHeight: '45px',
                    width: '70%',
                    margin: '0 auto 20px auto',
                  }}
                >
                  One Platform, All You Need – Make More Profit, Reach More Customers, Work Less.
                </Typography>
                {/* <Box className="text-center">
                  <Button
                    variant="contained"
                    className="start-growing-btn"

                  >
                    Start Growing Today
                  </Button>
                </Box> */}
                {/* Search bar code */}
                <Box sx={{ width: '70%', margin: '0 auto' }}>
                  <FormControl fullWidth sx={{ my: 1 }} variant="outlined">
                    <OutlinedInput
                      id="subCategoryInput"
                      type="text"
                      placeholder="Search here..."
                      // required
                      // disabled={categoriesTreeFilter.length === 0}
                      fullWidth
                      // disabled
                      margin="none"
                      value={vendorSearchVal}
                      // onChange={handleVendorSearchInput}
                      onFocus={(e)=>{
                        e.preventDefault();
                        handleClick(e);
                      }}
                      sx={{
                        backgroundColor: 'white',
                        height: '64px !important',
                      }}
                      // onFocus={handleClick}
                      startAdornment={
                        <SearchIcon
                          style={{
                            margin: '0px',
                            width: '35px',
                            height: '35px',
                          }}
                        />
                      }
                      endAdornment={
                        <Button variant="contained" className="btn-search" onClick={(e)=>{
                          e.preventDefault();
                          handleClick(e);
                        }}>
                          Search
                        </Button>
                      }
                    />
                    {open && (
                      <div
                        style={{
                          position: 'absolute',
                          boxShadow: '0px 2px 14px -3px rgba(0,0,0,0.5)',
                          top: '50px',
                          background: 'white',
                          zIndex: 1,
                          borderRadius: 4,
                          width: '100%',
                        }}
                      >
                        {searchOptions?.length > 0 &&
                          searchOptions?.map((option, vnIdx) => {
                            return (
                              <Box
                                style={{
                                  marginRight: '10px',
                                  marginLeft: '10px',
                                }}
                                key={vnIdx}
                                onClick={() => {
                                  if (option?.registration_type[0] == 2) {
                                    console.log('renderOption clicked b2b');
                                    // dispatch(getVendorDetails(option.id));
                                    setSearchOptions([]);
                                    dispatch(resetSearchVendors());
                                    history.push(`/b2b-detail/${option.id}`);
                                  } else {
                                    //registration_type == 2
                                    console.log('renderOption clicked FL');
                                    // dispatch(getVendorDetails(option.id));
                                    setSearchOptions([]);
                                    dispatch(resetSearchVendors());
                                    history.push(
                                      `/freelancer-detail/${option.id}`
                                    );
                                  }
                                }}
                              >
                                <div>
                                  {option?.registration_type &&
                                  option?.registration_type[0] == 2
                                    ? option?.company_name
                                    : option?.name}
                                </div>
                                <div>{option?.city ? option.city : ''}</div>
                                <br />
                              </Box>
                            );
                          })}
                      </div>
                    )}
                  </FormControl>
                </Box>
              </div>
            </div>

            <form style={{ marginTop: '0px' }}>
              <form style={{ marginTop: '51px' }}>
                {renderHomePageWidgets()}
                {userData?.registration?.registration_type !== FREELANCER_ID ? (
                  <CreditServicesTemp />
                ) : (
                  <></>
                )}
              </form>
              {/* <Box>
                <Grid container spacing={4} justifyContent="center" className='mt-4 mb-5'>
                  
                  <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                      <Box className="inner-box position-relative">
                        <Typography className='plan-type'>Basic</Typography>
                        <Typography className='plan-price'>Free</Typography>
                        <Typography className='plan-info'>Basic package</Typography>

                      </Box>

                      <Box className="benefits-services">
                        <ul className='package-list'>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Marketplace Listing</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Requirement posting</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Connects</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Inquiries</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Digital Business Card</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>WhatsApp Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Google Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Single Pager Website creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Digital Catalog</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Instagram profile</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Facebook profile</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>LinkedIn profile</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Dedicated Helpline</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Advisory Services</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Email Marketing</Typography>
                          </li>
                        </ul>
                      </Box>

                      <Typography className="caption">Terms & conditions apply*</Typography>

                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        className="get-now-btn"
                      // onClick={toggleCallBackDrawer}
                      >
                        Get Now
                      </Button>


                    </Box>
                  </Grid>

                  
                  <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                      <Box className="inner-box position-relative">
                        <Box className="d-flex justify-content-between">
                          <Typography className='plan-type'>eStarter</Typography>
                          <Typography className='offer-ribbon'>LIMITED TIME OFFER!</Typography>
                        </Box>
                        <Typography className='plan-price'>₹6,000 <span className='now-text'>Now</span> <span className='free-text'>FREE!!</span></Typography>
                        <Typography className='plan-info'>Come Experience elynker</Typography>

                      </Box>

                      <Box className="benefits-services">
                        <ul className='package-list'>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Marketplace Listing</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Requirement posting</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Connects</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Inquiries</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Digital Business Card</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>WhatsApp Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Google Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Single Pager Website creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Digital Catalog (Upto <span className='list-text-bold'>50</span> products/services)</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Instagram profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Facebook profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>LinkedIn profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Dedicated Helpline</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Advisory Services</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon disable' />
                            <Typography className='list-text disable'>Email Marketing</Typography>
                          </li>
                        </ul>
                      </Box>

                      <Typography className="caption">Terms & conditions apply*</Typography>

                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        className="get-now-btn"
                      // onClick={toggleCallBackDrawer}
                      >
                        Get Now
                      </Button>


                    </Box>
                  </Grid>

                  
                  <Grid item xs={12} sm={6} md={4} sx={{ display: 'flex' }}>
                    <Box className="package-type" sx={{ flexGrow: 1 }}>
                      <Box className="inner-box position-relative">
                        <Typography className='plan-type'>eSsential</Typography>
                        <Typography className='plan-price'>₹0,00 </Typography>
                        <Typography className='plan-info'>Coming Soon!!!</Typography>

                      </Box>

                      <Box className="benefits-services">
                        <ul className='package-list'>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Marketplace Listing</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Requirement posting</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Connects</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Unlimited Inquiries</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Digital Business Card</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>WhatsApp Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Google Business Profile creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>Single Pager Website creation</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Digital Catalog (Upto <span className='list-text-bold'>50</span> products/services)</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Instagram profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Facebook profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text'>LinkedIn profile with <span className='list-text-bold'>12</span> templatized posts </Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Dedicated Helpline</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Advisory Services</Typography>
                          </li>
                          <li>
                            <CheckCircleIcon className='check-icon enabled' />
                            <Typography className='list-text '>Email Marketing</Typography>
                          </li>
                        </ul>
                      </Box>

                      <Typography className="caption">Terms & conditions apply*</Typography>

                      <Button
                        type="button"
                        fullWidth
                        variant="contained"
                        className="get-now-btn"
                      // onClick={toggleCallBackDrawer}
                      >
                        Get Now
                      </Button>


                    </Box>
                  </Grid>

                </Grid>
              </Box> */}
              <Box sx={{ marginTop: '75px', marginBottom: '80px' }}>
                <Grid
                  container
                  rowSpacing={3}
                  columnSpacing={4}
                  sx={{ width: '80%', margin: '0 auto' }}
                >

                  <Grid item md={5} lg={5} className="ps-0">
                    <Typography
                      variant="h4"
                      align="left"
                      alignItems="center"
                      className="fs-36 text-black mb-3 fw-bold font-skmodernist"
                    >
                      Elynker is where your needs meet solutions.
                    </Typography>
                    <Typography
                      variant="body1"
                      align="left"
                      className="fs-15 text-gray fw-normal mb-3 font-gotham text-justify"
                      gutterBottom
                    >
                      Elynker is your ultimate partner in transforming how
                      Micro, Small, and Medium Enterprises (MSMEs) thrive in the
                      digital world. We offer innovative solutions to boost your
                      online presence, optimise financial management, and
                      enhance customer engagement. Our comprehensive services
                      are designed to help you streamline operations, reach new
                      customers, and drive sales effectively. Discover how
                      Elynker can make your path to online growth easier and
                      help your business progress. With our personalised
                      support, you can concentrate on what you do best while we
                      assist you in establishing a strong online presence.
                    </Typography>

                    <Typography className="fs-17 text-dark fw-semibold mb-2 font-gotham text-justify">
                      Why Choose Elynker?
                    </Typography>

                    <List
                      className="fs-15 text-gray fw-normal mb-3 font-gotham text-justify"
                      sx={{ listStyleType: 'disc', pl: 2 }}
                    >
                      <ListItem
                        sx={{
                          display: 'list-item',
                          padding: '0px',
                          textAlign: 'justify',
                        }}
                      >
                        <ListItemText primary="Elynker makes digital transformation easy by bringing all services and solutions together in one place." />
                      </ListItem>
                      <ListItem
                        sx={{
                          display: 'list-item',
                          padding: '0px',
                          textAlign: 'justify',
                        }}
                      >
                        <ListItemText primary="We are India’s leading marketplace, offering a one-stop solution for businesses of all sizes." />
                      </ListItem>
                      <ListItem
                        sx={{
                          display: 'list-item',
                          padding: '0px',
                          textAlign: 'justify',
                        }}
                      >
                        <ListItemText primary="Providing everything you need to progress in today’s digital market." />
                      </ListItem>
                    </List>

                    <div className="custom-accordion mobile-accordion">
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel1-content"
                          id="panel1-header"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                          className="fs-15 fontskmodernist text-darkgrey custom-summary"
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="What is Elynker?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Elynker is a comprehensive platform designed to
                          empower MSMEs and B2B businesses by providing a suite
                          of tailored services. Our tools and resources help you
                          manage your digital presence and access high-quality
                          vendors, enabling you to thrive in the competitive
                          business landscape.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel2-content"
                          id="panel2-header"
                          className="fs-15 fontskmodernist"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="How can Elynker help my business grow?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Elynker offers a variety of services, including
                          digital business cards, digital catalogues, website
                          development, chat engagement solutions, business
                          listing, and advisory services. By leveraging these
                          resources, you can enhance your online visibility,
                          streamline operations, and connect with potential
                          partners and customers, ultimately driving growth and
                          success.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel3-content"
                          id="panel3-header"
                          className="fs-15 fontskmodernist"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="What types of businesses can benefit from Elynker?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Elynker is designed for a wide range of businesses,
                          including MSMEs, B2B enterprises, service agencies,
                          startups, and home business owners. Our platform
                          caters to various industries, providing tailored
                          solutions that meet the unique needs of each business
                          type.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel4-content"
                          id="panel4-header"
                          className="fs-15 fontskmodernist"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="What is the process for finding partners and suppliers on Elynker?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Elynker facilitates connections between businesses and
                          potential partners or suppliers through our networking
                          features. You can search for partners based on your
                          industry, needs, and location, helping you forge
                          valuable relationships that drive growth and
                          innovation.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel3-content"
                          id="panel3-header"
                          className="fs-15 fontskmodernist"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="What digital tools does Elynker offer to support my business?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Elynker provides a comprehensive suite of digital
                          services, including website builders, social media
                          management platforms and more. These tools are
                          designed to enhance your operational efficiency and
                          improve your online presence.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel3-content"
                          id="panel3-header"
                          className="fs-15 fontskmodernist"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="How can Elynker assist with compliances for MSMEs?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Elynker works with expert agencies to connect you with
                          legal and compliance professionals who can help you
                          understand regulations. Our advisory services keep
                          your business compliant, reducing risks so you can
                          focus on what you do best.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel3-content"
                          id="panel3-header"
                          className="fs-15 fontskmodernist"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="What kind of support can I expect from Elynker’s concierge services?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Elynker’s concierge services provide dedicated support
                          tailored to your business needs. You will have a
                          direct line for quick solutions, personalised
                          assistance, and access to a wide range of resources,
                          ensuring you receive the help you need when you need
                          it.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel3-content"
                          id="panel3-header"
                          className="fs-15 fontskmodernist"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="Are there financial services available through Elynker?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Yes, Elynker connects businesses with financial
                          institutions that offer tailored credit facilities,
                          loans, and other financing options. Our platform helps
                          you navigate the financial landscape to secure the
                          funding necessary for growth and investment.
                        </AccordionDetails>
                      </Accordion>
                      <Accordion>
                        <AccordionSummary
                          expandIcon={
                            <ExpandMoreIcon className="expand-more-icon" />
                          }
                          aria-controls="panel3-content"
                          id="panel3-header"
                          className="fs-15 fontskmodernist"
                          sx={{
                            display: 'flex',
                            flexDirection: 'row',
                            justifyContent: 'flex-start',
                            alignItems: 'start',
                          }}
                        >
                          <ListItemIcon
                            className="align-items-start"
                            sx={{
                              paddingTop: '10px',
                            }}
                          >
                            <CircleIcon
                              style={{
                                color: '#1843A0',
                                width: '7px',
                                height: '7px',
                              }}
                            />
                          </ListItemIcon>
                          <ListItemText primary="What are the costs associated with using Elynker’s services?" />
                        </AccordionSummary>
                        <AccordionDetails>
                          Elynker offers various pricing plans depending on the
                          services you choose. For detailed information on
                          pricing and packages, please visit our subscription
                          page.
                          <br></br>
                          <Link
                            className="fs-14 font-gotham mt-2 "
                            // href="https://support.elynker.com"
                            underline="always"
                          >
                            {'support@elynker.com'}
                          </Link>
                          <Typography className="fs-14 font-gotham mt-1 text-gray">
                            +91 9820596537
                          </Typography>
                          <Typography className="fs-14 font-gotham text-gray">
                            {'Remember us with ease -  '}
                            <Link
                              component="button"
                              underline="none"
                              variant="body2"
                              color="#FFF"
                              onClick={() => {
                                window.location.href = 'tel:+919820596537';
                              }}
                            >
                              9820 LYNKER
                            </Link>
                          </Typography>
                        </AccordionDetails>
                      </Accordion>
                    </div>
                  </Grid>


                  <Grid item md={7} lg={7} className="pt-0">
                    <img
                      width="50%"
                      style={{ borderRadius: '20px', position: 'relative' }}
                      src={require('../../assets/ElynkerImage1.png')}
                    />
                    <img
                      width="25%"
                      style={{
                        borderRadius: '20px',
                        position: 'absolute',
                        zIndex: 1,
                        marginTop: '18%',
                        marginLeft: '-12%',
                      }}
                      src={require('../../assets/ElynkerImage2.png')}
                    />
                    <img
                      width="20%"
                      // height="350vh"
                      style={{
                        borderRadius: '20px',
                        position: 'absolute',
                        zIndex: 2,
                        marginTop: '4%',
                        marginLeft: '4%',
                      }}
                      src={require('../../assets/ElynkerImage3.png')}
                    />
                  </Grid>
                </Grid>
              </Box>
              <Divider
                component="div"
                className="main-divider"
                role="presentation"
              />
              <Box sx={{ marginTop: '65px', marginBottom: '80px' }}>
                <Typography
                  variant="h4"
                  align="left"
                  alignItems="center"
                  className="fs-36 text-black mb-5 fw-bold font-skmodernist text-center"
                >
                  What Our Users Say
                </Typography>
                <Carousel
                  preventMovementUntilSwipeScrollTolerance={true}
                  swipeScrollTolerance={50}
                  className="carousel-container"
                  showIndicators={true}
                  showThumbs={false}
                >
                  {carouselItems &&
                    carouselItems?.map((item) => (
                      <div className="carousel-web-field" key={item.id}>
                        <div
                          style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginBottom: '25px',
                            justifyContent: 'center',
                          }}
                        >
                          <QuotesIcon
                            style={{
                              marginRight: '10px',
                              transform: 'rotate(180deg)',
                            }}
                          />
                        </div>
                        <Typography
                          align="center"
                          variant="body1"
                          className="fs-16 text-darkgrey fw-medium font-gotham mb-4"
                        >
                          {item.paragraph}
                        </Typography>
                        <div>
                          <Typography
                            align="center"
                            variant="body2"
                            className="fs-18 text-black fw-bold font-skmodernist mb-1 "
                          >
                            {item.text1}
                          </Typography>
                          <Typography
                            align="center"
                            variant="body2"
                            className="fs-13 text-darkgrey fw-normal font-gotham"
                          >
                            {item.text2}
                          </Typography>
                        </div>
                      </div>
                    ))}
                </Carousel>
              </Box>
            </form>
            <div className="homepage-footer">
              <WebFooter
                handleTerms={handleTerms}
                handlePrivacy={handlePrivacy}
                openInNewTab={openInNewTab}
                handleContactUs={handleContactUs}
                userData={userData}
              />
            </div>
          </Grid>
        </Grid>
      </Container>
    </ThemeProvider>
  );
};
WebHomepageForm.propTypes = {
  renderHomePageWidgets: func,
  registrationData: any,
  flancerLvl1Categories: any,
  BusinessCategory: any,
  // CreditServices: any,
  ElynkerTools: any,
  userStatus: any,
  widgets: any,
  resetAllVendorData: any,
  b2bLvl1Categories: any,
  tools: any,
  // handleClickOnDetail: any,
  handleViewTools: any,
  vendorSearchVal: any,
  open: any,
  handleVendorSearchInput: func,
  handleClick: func,
  searchOptions: any,
  setSearchOptions: any,
  resetSearchVendors: any,
  carouselItems: any,
  handleTerms: any,
  handlePrivacy: any,
  openInNewTab: func,
  homepageJobsActive: array,
  handleShippingDelivery: func,
  handleCancellationRefund: func,
  handleContactUs: func,
  CreditServicesTemp: any,
  openModal: bool,
  handleClose: func,
  // handleOpen,
  handleConfirm: func,
  userType: number,
  enquiry: object,
  waEnquiry: object,
  openPreRegisterModal: bool,
  setOpenPreRegisterModal: func,
  nameParam: string,
  mobileNoParam: string,
  emailParam: string,
  typeParam: string,
  startOnboarding: func,
  startSubscription: func,
  openSuccCallModal: bool,
  handleCloseSuccCallModal: func,
  errorMsg: string
};
export default WebHomepageForm;
