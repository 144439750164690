import {
  SAVE_BUSINESS_DOCUMENTS,
  SET_ACTIVE_STEP,
  SET_STEPPER_DATA,
  RESET_B2B_ONBOARD_DATA,
} from '../types';
import { COMPANY_NAME } from '../../../CompanyDetails/redux/types';
import { SAVE_SIGNIN_DATA } from '../../../Otp/redux/types';
import {
  getBusinessDocuments,
  postBusinessDocuments,
  putUpdateOutReachUser,
  putUpdateUser,
} from '../../../../utils/APIs/endpoints';
import {
  CIN_NUMBER,
  GST_NUMBER,
  GST_REASON,
  HAS_GST_NO,
  PAN_NUMBER,
} from '../../../../components/BussinessDocumentsForm/IN/redux/types';
import { ACN_NUMBER } from '../../../../components/BussinessDocumentsForm/AU/redux/types';
import {
  AUSTRALIA,
  INDIA,
} from '../../../../components/BussinessDocumentsForm/constant';
import { MENU_ITEM } from '../../../../components/BussinessDocumentsForm/IN/IN-BussinessDocumentForm';
import { setPincode as setPincodeFL } from '../../../FreeLancer/FL-Profile-Details/redux/actions/fl-profile-details-action';
import { setPincode } from '../../../ProfileDetails/redux/actions/profile-details-form-action';
import {
  SET_PROFILE_DETAILS_ADDRESS_1,
  SET_PROFILE_DETAILS_PINCODE,
  SET_SELECTED_WHATSAPP,
} from '../../../ProfileDetails/redux/types';

export const setActiveStep = (newStep) =>
  function (dispatch) {
    dispatch({ type: SET_ACTIVE_STEP, payload: newStep });
  };

export const saveBusinessDocuments = (businessDoc) =>
  function (dispatch) {
    dispatch({ type: SAVE_BUSINESS_DOCUMENTS, payload: businessDoc });
  };

export const submitBusinessDocuments = (
  id = null,
  accessToken = null,
  businessDocuments = null,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function (dispatch) {
    console.log('[b2b-onboarding-steps-action] id', id);
    console.log('[b2b-onboarding-steps-action] documents', businessDocuments);
    if (id !== null && accessToken !== null && businessDocuments !== null) {
      console.log(
        '[b2b-onboarding-steps-action] exec.. submitBusinessDocuments'
      );
      try {
        const response = await postBusinessDocuments(id, businessDocuments);

        console.log(
          '[b2b-onboarding-steps-action] submitBusinessDocuments response ',
          response.data
        );
        if (response.status === 200) {
          await dispatch({
            type: SAVE_BUSINESS_DOCUMENTS,
            payload: businessDocuments,
          });
          successCallback();
        }
      } catch (error) {
        console.log(
          '[b2b-onboarding-steps-action]  submitBusinessDocuments Error',
          JSON.stringify(error, null, 2)
        );
        errroCallback();
      }
    }
  };

export const getExistingBusinessDocuments = (id, country_code_name = null) =>
  async function (dispatch) {
    try {
      // console.log('user id', id);
      const response = await getBusinessDocuments(id);
      if (response.status === 200 && response.data?.length > 0) {
        response.data.forEach((doc) => {
          dispatch({ type: COMPANY_NAME, payload: doc.company_name });
          resolveBusinessDocByCountry(country_code_name, doc, dispatch);
        });
        await dispatch({
          type: SAVE_BUSINESS_DOCUMENTS,
          payload: response.data,
        });
      }
    } catch (error) {
      console.log(
        '[b2b-onboarding-steps-action] getExistingBusinessDocuments Error',
        JSON.stringify(error, null, 2)
      );
    }
  };

export const setStepperData = (stepperData) =>
  function (dispatch) {
    dispatch({ type: SET_STEPPER_DATA, payload: stepperData });
  };

export const updateUserData = (
  olduserData,
  newUserData,
  userId,
  accessToken,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function (dispatch) {
    try {
      console.log('==new user data updateUserData', newUserData);
      const response = await putUpdateUser(userId, newUserData);
      if (response.status === 200) {
        let newUsrData = JSON.parse(JSON.stringify(olduserData));
        newUsrData.registration = response.data?.updatedRegistration;
        await dispatch({ type: SAVE_SIGNIN_DATA, payload: newUsrData });
        successCallback();
        dispatch(setPincode(null));
        dispatch(setPincodeFL(null));
        dispatch({
          type: SET_PROFILE_DETAILS_ADDRESS_1,
          payload: newUsrData?.registration?.address1,
        });
        dispatch({
          type: SET_SELECTED_WHATSAPP,
          payload: newUsrData?.registration?.whatsapp_number,
        });
        dispatch({
          type: SET_PROFILE_DETAILS_PINCODE,
          payload: newUsrData?.registration?.pincode,
        });
      } else {
        console.log(
          '[b2b-onboarding-steps-action] updateUserData ERROR',
          response.data
        );
        errroCallback();
      }
    } catch (error) {
      console.log('[b2b-onboarding-steps-action] updateUserData ERROR', error);
      errroCallback();
    }
  };

export const putOutReachUserData = (
  data,
  regId,
  userId,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function () {
    try {
      const response = await putUpdateOutReachUser(data, regId, userId);
      if (response.status === 200) {
        successCallback();
      } else {
        console.log(
          '[b2b-onboarding-steps-action] upOutReachUserData ERROR',
          response.data
        );
        errroCallback();
      }
    } catch (error) {
      console.log(
        '[b2b-onboarding-steps-action] upOutReachUserData ERROR',
        error
      );
      errroCallback(error.response?.data?.error);
    }
  };

export const updateVendorUserData = (
  olduserData,
  newUserData,
  userId,
  accessToken,
  successCallback = () => {},
  errroCallback = () => {}
) =>
  async function () {
    try {
      console.log('==new user data updateUserData', newUserData);
      const response = await putUpdateUser(userId, newUserData);
      if (response.status === 200) {
        let newUsrData = { ...olduserData };
        newUsrData.registration = response.data?.updatedRegistration;
        successCallback();
      } else {
        console.log(
          '[b2b-onboarding-steps-action] updateUserData ERROR',
          response.data
        );
        errroCallback();
      }
    } catch (error) {
      console.log('[b2b-onboarding-steps-action] updateUserData ERROR', error);
      errroCallback();
    }
  };

export const resetB2BStepData = () =>
  function (dispatch) {
    dispatch({ type: RESET_B2B_ONBOARD_DATA });
  };

const resolveBusinessDocByCountry = (codeName, doc, dispatch) => {
  switch (codeName) {
    case INDIA.countryCodeName:
      if (doc.document_name === 'CIN') {
        dispatch({ type: CIN_NUMBER, payload: doc.document_number });
      } else if (doc.document_name === 'GST') {
        if (!doc.is_provided) {
          const getReasontext = MENU_ITEM.findIndex((rea) =>
            rea.text
              .toLowerCase()
              .includes(doc?.no_document_reason?.toLowerCase())
          );
          dispatch({ type: HAS_GST_NO, payload: '0' });
          if (getReasontext !== -1) {
            dispatch({
              type: GST_REASON,
              payload: MENU_ITEM[getReasontext].value,
            });
          } else {
            dispatch({
              type: GST_REASON,
              payload: MENU_ITEM[2].value,
            });
          }
          dispatch({ type: GST_NUMBER, payload: doc.document_number });
        } else {
          dispatch({ type: HAS_GST_NO, payload: '1' });
          dispatch({
            type: GST_REASON,
            payload: '0',
          });
          dispatch({ type: GST_NUMBER, payload: doc.document_number });
        }
      } else if (doc.document_name === 'PAN') {
        dispatch({ type: PAN_NUMBER, payload: doc.document_number });
      }
      break;
    case AUSTRALIA.countryCodeName:
      if (doc.document_name === 'ACN') {
        dispatch({ type: ACN_NUMBER, payload: doc.document_number });
      }
      break;
    default:
      break;
  }
};
