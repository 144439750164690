import React, { useMemo } from 'react';
import { Pagination, Stack, Typography, Box } from '@mui/material';
import PropTypes from 'prop-types';

const CustomPaginationMobile = ({
  page,
  numPages,
  count,
  rowsPerPage,
  onPageChange,
}) => {
  const handlePageChange = (event, value) => {
    onPageChange(value - 1);
  };

  // Memoized calculation of startIndex and endIndex
  const [startIndex, endIndex] = useMemo(() => {
    if (count === 0) return [0, 0];
    const start = (page - 1) * rowsPerPage + 1;
    const end = Math.min(page * rowsPerPage, count);
    return [start, end];
  }, [page, count, rowsPerPage]);

  return (
    <Box sx={{ padding: '10px', borderTop: '1px solid #E4E4E4' }}>
      <Typography
        variant="body1"
        className="fs-15 font-skmodernist"
        sx={{ color: '#5F6165' }}
      >
        Showing {count ? startIndex : 0}-{count ? endIndex : 0} of {count}{' '}
        Results
      </Typography>
      <Stack spacing={2} direction="row">
        <Pagination
          count={numPages}
          page={page}
          onChange={handlePageChange}
          shape="rounded"
          variant="outlined"
          size="small"
        />
      </Stack>
    </Box>
  );
};

CustomPaginationMobile.propTypes = {
  page: PropTypes.number.isRequired,
  count: PropTypes.number.isRequired,
  rowsPerPage: PropTypes.number.isRequired,
  onPageChange: PropTypes.func.isRequired,
  numPages: PropTypes.number.isRequired,
};

export default CustomPaginationMobile;
