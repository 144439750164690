import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
// import DialogActions from '@mui/material/DialogActions';
// import FormControlLabel from '@mui/material/FormControlLabel';
import Button from '@mui/material/Button';
import { Box, Divider, Link, Typography } from '@mui/material';
import PropTypes from 'prop-types';
import { ReactComponent as CloseIcon } from '../../assets/close-icon.svg';
import { ReactComponent as PersonalisedWebIcon } from '../../assets/free-personalised-website-icon.svg';
import { ReactComponent as SMToolsIcon } from '../../assets/social-media-tools-icon.svg';
import { ReactComponent as DigitalCatIcon } from '../../assets/free-digital-catalogue-icon.svg';
// import { ReactComponent as ThumbsUpIcon } from '../../assets/get-in-touch-thumbs.svg';
// import { ReactComponent as TeamIcon } from '../../assets/features-team-icon.svg';
// import PersonalisedBusinessIcon from '../../assets/personalised-business-icon.png';




const PreRegisterModal = ({ open, onClose, startOnboarding, startSubscription }) => {


    // const handleSave = () => {
    //     // Handle save logic here
    //     onClose();
    // };

    // const handleCancel = () => {
    //     onClose(true); // Close the modal
    // };

    return (
        <Dialog open={open} onClose={onClose} maxWidth="md" fullWidth>
            <DialogTitle className="custom-border-header pb-0">
                <Box className="position-absolute d-flex justify-content-end button-close" sx={{right: '20px'}} >
                    <CloseIcon onClick={() => {
                        onClose();
                    }} style={{ cursor: 'pointer' }} />
                </Box>


            </DialogTitle>
            <DialogContent className="modal-container pre-register-modal-container">
                <Typography variant="h4" className="modal-title">
                    Welcome to elynker
                </Typography>
                <Box className="modal-content">

                    <Typography variant="body1" className="modal-subtitle">
                        Join 2L+ Businesses In Growing Their Digital Presence
                    </Typography>

                    <div className="features-outer-box">
                        <div className="features-box">
                            {/* <Box className="team-box">
                                <TeamIcon />
                            </Box> */}
                            <Box className="features-center-box">
                                <Box className="feature personalised-web-feature">
                                    <Box className="icon ">
                                        <PersonalisedWebIcon className='website-icon'/>
                                    </Box>
                                    <Box>
                                        <Typography variant="h6" className="feature-title">
                                            Personalized Website
                                        </Typography>
                                        <Typography variant="body2" className="feature-description">
                                        Get a Free Website For Your Business. <br></br>
                                        Make it easy for customers to find you, connect with you, and trust your brand.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider className="divider" />

                                <Box className="feature social-media-feature">
                                    <Box className="icon ">
                                        <SMToolsIcon className='social-media-icon'/>
                                    </Box>

                                    <Box>
                                        <Typography variant="h6" className="feature-title">
                                            Social Media Content
                                        </Typography>
                                        <Typography variant="body2" className="feature-description">
                                        288 Social Media Posts to Keep Your Brand Engaging Year-Round. <br></br>
                                        Stay in front of your audience with consistent posts that attract attention.
                                        </Typography>
                                    </Box>
                                </Box>
                                <Divider className="divider" />

                                <Box className="feature free-digital-feature">
                                    <Box className="icon ">
                                        <DigitalCatIcon className='catalogue-icon'/>
                                    </Box>

                                    <Box>
                                        <Typography variant="h6" className="feature-title">
                                            Free Digital Catalogue
                                        </Typography>
                                        <Typography variant="body2" className="feature-description">
                                        Free Digital Catalogue To Display Up To 20 Of Your Top Products. <br></br>
                                        Share it across platforms, send to clients, and use it to attract buyers.
                                        </Typography>
                                    </Box>
                                </Box>
                            </Box>
                            {/* <Box className="thumbsup-box">
                                <ThumbsUpIcon className='thumbsup-icon' />
                            </Box> */}

                        </div>
                        <Typography className="features-footer-text">
                            Do Not Miss Out On This & Many More Services With Elynker.
                        </Typography>
                    </div>




                    <Button variant="contained" className="start-onboarding-button" onClick={()=>{
                        startOnboarding();
                    }}>
                        Start Onboarding
                    </Button>

                    <Link component="button" className="digital-packages-link" onClick={(e)=>{
                        startSubscription(e);
                    }}>
                        Explore our packages
                    </Link>
                </Box>



            </DialogContent>



        </Dialog>


    );
};

PreRegisterModal.propTypes = {
    open: PropTypes.bool.isRequired, // 'open' prop should be a boolean and is required
    onClose: PropTypes.func.isRequired, // 'onClose' prop should be a function and is required
    startOnboarding: PropTypes.func,
    startSubscription: PropTypes.func
};

export default PreRegisterModal;